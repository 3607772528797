.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.footer-stats {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #1677ff;
  font-size: 12px;
  margin: 15px 0;
}

.stats-icon {
  color: #2481cc;
  font-size: 14px;
  width: 14px;
}



.active-users-icon {
  animation: pulse 1.5s infinite;
  margin-right: 3px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}