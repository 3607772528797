.gameContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.styledCard {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 20px;
}

.tapContainer {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.bankImage {
  cursor: pointer;
  transition: transform 0.2s ease;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
}

.bankImage:hover {
  transform: scale(1.05);
}

.bankImage:active {
  transform: scale(0.95);
}

.pointsAnimation {
  position: absolute;
  color: #52c41a;
  font-size: 24px;
  font-weight: bold;
  animation: floatUp 1s ease-out forwards;
  pointer-events: none;
}

@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.progressContainer {
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
}

.styledProgress :global(.ant-progress-text) {
  font-weight: bold;
  color: #1890ff;
}

.countdownContainer :global(.ant-statistic .ant-statistic-title) {
  font-size: 18px;
  color: #666;
}

.countdownContainer :global(.ant-statistic .ant-statistic-content) {
  font-size: 24px;
  color: #1890ff;
}

.title {
  color: #1890ff;
}

.tapsText {
  font-size: 16px;
  color: #666;
}