/* Общие стили */
.container {
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.title {
  color: #007aff;
}

.subtitle {
  font-size: 14px;
  color: #595959;
}

/* Стили для карточек */
.card {
  border-radius: 8px;
  height: 100%;
  transition: all 0.3s;
}

.featureCard {
  composes: card;
}

.featureCardContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.priceCard {
  margin-top: 24px;
  border-radius: 8px;
  text-align: center;
}

.trialCard {
  margin-top: 16px;
  border-radius: 8px;
  background: #f0f9ff;
  border-color: #007aff;
}

.premiumInfoCard {
  margin-top: 20px;
  border-radius: 8px;
}

/* Стили для иконок */
.icon {
  font-size: 32px;
  color: #007aff;
}

/* Стили для текста */
.featureTitle {
  margin-left: 12px;
  font-size: 16px;
  color: #262626;
}

.featureDescription {
  margin-top: 16px;
  color: #595959;
}

.priceText {
  font-size: 20px;
  color: #007aff;
}

.priceAmount {
  font-size: 24px;
}

.trialTitle {
  font-size: 18px;
  color: #262626;
}

.trialDescription {
  color: #595959;
  margin: 8px 0;
}

/* Стили для кнопок */
.primaryButton {
  background: #007aff;
  border-color: #007aff;
  border-radius: 8px;
}

/* Стили для контейнеров */
.flexColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.featuresRow {
  margin-top: 24px;
}

.trialContent {
  margin-left: 16px;
  flex: 1;
}

/* Стили для PremiumContent */
.historyTitle {
  color: #595959;
}

.historyList {
  background: #ffffff;
  border-radius: 8px;
  padding: 12px;
}

.historyEmptyText {
  color: #8c8c8c;
  display: block;
  text-align: center;
  padding: 16px;
}

.divider {
  margin: 24px 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.secondaryText {
  padding: 16px;
  text-align: center;
}

/* Дополнительные стили для PremiumContent */
.premiumInfoText {
  font-size: 16px;
}

.premiumHighlight {
  color: #007aff;
  font-weight: bold;
}

.trialTag {
  color: #007aff;
  margin-left: 10px;
}

.purchaseAmount {
  color: #007aff;
  font-weight: bold;
}

.purchaseDate {
  color: #8c8c8c;
}