.container {
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.title {
  margin-bottom: 24px;
  font-weight: 600;
  color: #333;
}

.formItem {
  margin-bottom: 10px;
}

.bonusSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f8ff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #e6f0ff;
  transition: all 0.3s ease;
}

.bonusSwitch:hover {
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.1);
}

.bonusSwitchActive {
  background-color: #e6f7ff;
  border-color: #91caff;
}

.bonusLabel {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #333;
}


.messageText {
  margin: 10px 0;
  display: block;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
  border-left: 4px solid #1890ff;
}

.profitBonus {
  display: flex;
  justify-content: center;
  background-color: rgba(82, 196, 26, 0.1);
  padding: 8px 12px;
  border-radius: 6px;
  margin-top: 8px;
  font-size: 14px;
}

.profitBonus strong {
  color: #52c41a;
  margin-left: 4px;
}

.profitBonus svg {
  margin-right: 8px;
  color: #52c41a;
}

.insuranceCheckbox {
  margin-bottom: 8px;
}

.riskLink {
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.inputNumber {
  width: 100%;
}

.popularRates {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  color: #8f8f8f;
}

.popularRateLink {
  background-color: #f0f5ff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 13px;
  transition: all 0.3s;
}

.popularRateLink:hover {
  background-color: #e6f7ff;
}

.marginLoansSection {
  margin-top: 16px;
  margin-bottom: 16px;
}

.marginTitle {
  display: block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.65);
}

.marginRadioGroup {
  margin: 15px 0;
}

.marginLearnMore {
  display: block;
  margin-bottom: 20px;
}

.divider {
  margin: 24px 0;
}

.alertInfo {
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.alertInfo strong {
  color: #1890ff;
}

.alertLink {
  color: #1677ff;
  cursor: pointer;
}

.bonusTermInfo {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  margin-bottom: 16px;
  display: block;
}

.submitButton {
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}
