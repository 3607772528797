.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cardContainer {
  max-width: 500px;
  margin: 20px auto;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.titleIcon {
  color: #faad14;
  font-size: 24px;
}

.titleText {
  margin: 0;
}

.timerCard {
  background: #f5f5f5;
  border-radius: 10px;
}

.timerIcon {
  font-size: 40px;
  color: #1890ff;
}

.timerContainer {
  width: 100%;
  justify-content: center;
}

.timerText {
  font-size: 16px;
}

.actionSpace {
  margin-top: 16px;
}

.bonusCard {
  border-radius: 10px;
}

.bidCard {
  border-radius: 10px;
  margin-bottom: 16px;
}

.statValue {
  color: #52c41a;
  font-size: 24px;
}

.bidButton {
  height: 50px;
  font-size: 16px;
  border-radius: 8px;
}

.availableText {
  text-align: center;
  display: block;
}

.historyCard {
  border-radius: 10px;
  margin-top: 16px;
}

.bidItem {
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  animation: slideIn 0.5s ease-out;
}

.bidItemUser.bidItemUser {
  background-color: #f6ffed;
  cursor: default;
  padding: 10px;
}

.bidItemOther.bidItemOther {
  background-color: #fff1f0;
  cursor: pointer;
  padding: 10px;
}

.alertMessage {
  border-radius: 8px;
  margin-top: 16px;
}

.infoAlert {
  border-radius: 8px;
}

.fullWidth {
  width: 100%;
}

/* Стили для LastAuctionWinners */
.winnersCardContainer {
  max-width: 800px;
  margin: 20px auto;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.winnerItem.winnerItem {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  animation: slideIn 0.5s ease-out;
}

.winnerItemUser {
  background-color: #f6ffed;
  cursor: default;
}

.winnerItemOther {
  background-color: #fff1f0;
  cursor: pointer;
}

.truncatedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}