.listItemStyle {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  background: #fafafa;
  transition: all 0.3s ease;
  cursor: pointer;
}

.listItemHover:hover {
  background: #f0f0f0 !important;
}

.partnerCard {
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  margin: 16px;
}

.iconStyle {
  color: #1890ff;
  font-size: 20px;
}

.tagStyle {
  padding: 2px 8px;
  font-size: 12px;
  line-height: 16px;
  height: 20px;
}

.emptyStyle {
  margin: 40px 0;
}

.spinContainer {
  padding: 40px 0;
}

/* Стили для LoanOvernight */
.overnightCard {
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.statsCard {
  background: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 16px;
}

.rateValue {
  color: #52c41a;
  font-size: 24px;
}

.alertSuccess {
  margin-top: 16px;
  border-radius: 8px;
  text-align: left;
}

.checkboxCard {
  border-radius: 8px;
  margin-bottom: 8px;
}

.infoAlert {
  border-radius: 8px;
  margin-top: 16px;
}

.chartContainer {
  position: relative;
  width: 100%;
  height: 200px;
}

.chartLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
}

.usdtStatValue {
  color: #26A17B;
}

.tonStatValue {
  color: #0088CC;
}

.partnerLink {
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  cursor: pointer;
}

.iconLarge {
  font-size: 24px;
  color: #1890ff;
}

.textLeft {
  width: 100%;
  text-align: left;
}

.demandInfo {
  font-size: 12px;
  color: #999;
}