.balanceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.balanceRow:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  padding: 16px;
}

.title {
  margin-bottom: 24px;
}

.card {
  background: #f8fafc;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
}

.form {
  margin-top: 8px;
}

.input {
  border-radius: 8px;
  width: 100%;
}

.alert {
  border-radius: 8px;
  margin-bottom: 16px;
}

.loading {
  text-align: center;
  margin-top: 20px;
}

.buttonContainer {
  margin-top: 24px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalCard {
  background: #f8fafc;
}

.checkboxMargin {
  margin-bottom: 8px;
}

.tonAmountText {
  display: block;
  margin-top: 8px;
}

.maxButton {
  display: inline-block;
  background-color: #f0f5ff;
  color: #1890ff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.maxButton:hover {
  background-color: #e6f7ff;
  color: #096dd9;
}