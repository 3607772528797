.userAvatar {
  cursor: pointer;
}

.userAvatar:hover {
  transform: scale(1.05);
}

.likeButton {
  transition: all 0.3s ease;
}

.liked {
  color: #ff4d4f;
}

.animating {
  animation: pulse 0.5s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.postImageContainer {
  overflow: hidden;
  position: relative;
  max-height: 300px;
}

.postImageContainer img {
  transition: transform 0.3s ease;
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  object-position: top;
}

.postImageContainer:hover img {
  transform: scale(1.03);
}

.expandImageButton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  color: white;
  text-align: center;
  padding: 30px 0 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expandImageButton svg {
  margin-right: 5px;
}

.fullSizeImage {
  max-height: none !important;
  object-fit: contain !important;
}

.fullSizeContainer {
  max-height: none !important;
}

.postContent {
  font-size: 15px;
  line-height: 1.6;
  text-align: left;
  margin: 12px 0 16px;
}

.postContent p {
  text-align: left;
  margin-bottom: 12px;
}


.expandTextButton {
  padding: 0;
  margin: 0;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  color: #1890ff;
}

.expandTextButton:hover {
  color: #40a9ff;
}

.postContent a {
  color: #1890ff;
  text-decoration: none;
  border-bottom: 1px solid rgba(24, 144, 255, 0.3);
  transition: color 0.3s, border-color 0.3s;
  padding: 2px 0;
}

.postContent a:hover {
  color: #40a9ff;
  border-bottom-color: #40a9ff;
}

.postContent strong {
  font-weight: 600;
}

.postBottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
}

.postCount {
  display: flex;
  align-items: center;
}

.postCard {
  margin-bottom: 16px;
  border-radius: 8px;
  align-items: start;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
}

.premiumTag {
  position: relative;
  z-index: 1;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 8px 8px 0 0;
  width: 100%;
  text-align: center;
  display: inline-block;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #d4af37 0%, #f9d423 100%);
}

.commentsButton {
  transition: color 0.3s ease;
  cursor: pointer;
}

.commentsIcon {
  display: flex;
}

.imagePreviewMask {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePreviewIcon {
  margin-right: 8px;
}

.userName {
  font-size: 16px;
  display: block;
}

.timeStamp {
  margin-right: 12px;
}

.viewIcon {
  margin-right: 4px;
}

.info {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.editTextArea {
  margin-bottom: 10px;
  border-radius: 4px;
}

.saveButton {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cancelButton {
  margin-top: 10px;
}

.commentsModal {
  top: 10vh;
  padding-bottom: 0;
}

.commentsContainer {
  height: 70vh;
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  padding: 0 24px;
}

.commentsContent {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  padding-right: 12px;
}

@media (max-width: 576px) {
  .postContent {
    font-size: 14px;
  }

  .postImageContainer {
    max-height: 250px;
  }

  .postImageContainer img {
    max-height: 250px;
  }

  .expandImageButton {
    padding: 25px 0 8px;
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .postImageContainer {
    max-height: 200px;
  }

  .postImageContainer img {
    max-height: 200px;
  }
}
