.App {
  padding: 0 20px 20px 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #fff;
  min-height: 100vh;
  overflow-y: auto;
  color: black;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profile {
  display: flex;
  margin-top: 20px;
  padding: 10px;
  border-radius: 12px;
  background: var(--tgui--bg_color);
  justify-content: center;
}

.tabs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: space-between;
}

.tabs_content {
  justify-content: space-around;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.buttons {
  text-align: "center";
  cursor: "pointer";
}

.connect {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px 0;
}

.marketing-container {
  max-width: 100%;
  overflow-x: auto;
  padding: 16px;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.tap-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tap-image {
  cursor: pointer;
}

.ant-card .ant-card-meta-avatar {
  display: flex;
  align-items: center;
}

.points-animation {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fly-up 1s ease-out forwards;
  color: rgb(83, 82, 82);
  font-weight: bold;
  font-size: 24px;
  pointer-events: none;
}

.rates {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.loan-exchange-container {
  padding: 12px;
}

.exchange-card {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exchange-card:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.border {
  border-right: rgba(0, 0, 0, 0.15) 1px solid;
  padding-right: 15px;
}

.partners-level {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0 5px;
}

.history-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-wrap: wrap;
}

.history-item-price {
  display: flex;
  flex-direction: column;
}

.profile-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-item span {
  color: rgba(0, 0, 0, 0.65);
}

.profile-item .ant-tag {
  margin-left: 8px;
}

.loan-stars {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.infinite-scroll-component {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.ant-typography p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.ant-card-meta {
  margin-top: 10px;
}

.title_page {
  margin-top: 0;
}

.faq_page {
  display: flex;
  align-items: center;
  justify-content: end;
}

.mode_switch {
  margin-top: 2px;
}

.post_content {
  font-size: 14px;
  text-align: left;
  white-space: pre-line;
  line-height: 1.4;
}

.create_posts {
  display: flex;
  color: black;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 0 10px;
  margin: 30px auto 50px;
}

.create_posts_input {
  border: none;
  margin: 0 10px;
}

.partners_balance {
  display: flex;
  justify-content: space-between;
}

.ant-steps.ant-steps-inline .step-finish .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
  background-color: green;
  border-color: green;
}

.step-finish .ant-steps-item-title {
  color: green;
}

.ant-steps.ant-steps-inline .step-wait .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
  background-color: orange;
  border-color: orange;
}

.step-wait .ant-steps-item-title {
  color: orange;
}

.balance_item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 10px;
  padding: .8rem;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.balance_item:hover {
  background-color: #4096ff;
  color: white;
}

.balance_item_name {
  display: flex;
  gap: 10px;
  align-items: center;
}

.balance_item_price {
  font-weight: 600;
}

.tabs_panel {
  display: flex;
  padding: .7rem;
  gap: 10px;
  align-items: center;
  border: solid 1px #4096ff;
  border-radius: 10px;
  font-size: 14px;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
}

.tabs_panel:hover {
  background-color: #4096ff;
  color: white;
}

.dashboard {
  display: flex;
  flex-direction: column;
}

:where(.css-98ntnt).ant-steps.ant-steps-inline .ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: green;
}

.comment_contain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 10px;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s;
}

.comment_contain:hover {
  background-color: #fafafa;
}

.comment {
  display: flex;
  line-height: 14px;
  padding: 0 20px;
}

.comment_texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  line-height: 1.36;
  gap: 2px;
}

.comment_texts p {
  margin: 0;
}

.comment_author {
  font-weight: 700;
  min-height: 1.36em;
}

.comment_date {
  color: #818c99;
  font-size: 12px;
}

.comment_content {
  min-height: 1.36em;
  text-align: left;
  max-width: 250px;
}

.comments_icon {
  font-size: 12px;
  color: #818c99;
}

.create_comment {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  padding: 16px 24px;
  margin: 0 -24px;
}

.create_comment_input {
  display: flex;
  border: none;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 8px 12px;
}

.create_comment_input .ant-input {
  background-color: transparent;
  border: none;
  padding: 4px 0;
}

.create_comment_input .anticon {
  color: #1677ff;
  cursor: pointer;
  transition: color 0.3s;
}

.create_comment_input .anticon:hover {
  color: #4096ff;
}

:where(.css-98ntnt).ant-list-split .ant-list-header {
  border: none;
}

.post_bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #818c99;
  font-size: 12px;
}

.post_count {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notification_contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px;
  cursor: pointer;
}

.notification_list_item {
  display: flex;
  align-items: center;
}

.overnight-loans-card {
  margin: 10px;
  text-align:  left;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.progress_bar {
  margin: 10px;
}

.sales_item{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: #1677ff 1px solid;
  padding: 15px;
}

.sales_item_info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}

.faq-panel {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.faq-panel:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@keyframes fly-up {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-100px);
  }
}

.last-winners-container {
  padding: 20px;
}

.last-winners-list .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}

.back-button {
  margin-bottom: 20px;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.tg-select .ant-select-selector {
  border: none !important;
  border-radius: 8px !important;
  min-width: 150px;
  font-size: 16px !important;
  touch-action: manipulation;
}


select, input, textarea {
  font-size: 16px !important;
}

@media screen and (max-width: 768px) {
  * {
    touch-action: manipulation;
  }
}

.comments-modal .ant-modal-content {
  padding: 24px 24px 0 24px;
}
