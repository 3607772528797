.participantCard {
  margin-bottom: 16px;
  border-radius: 12px;
}

.topParticipantCard {
  background: #fafafa;
}

.userPositionCard {
  margin-bottom: 16px;
  border-radius: 12px;
  background: #e6f7ff;
}

.aboutCard {
  border-radius: 12px;
}

.participantContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.positionNumber {
  min-width: 32px;
  text-align: center;
  font-weight: bold;
}

.topPositionNumber {
  color: #f5a623;
}

.regularPositionNumber {
  color: #666;
}

.topAvatar {
  border: 2px solid #f5a623;
}

.userInfo {
  flex: 1;
}

.crownIcon {
  color: #f5a623;
  font-size: 20px;
}

.winnerContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.trophyIcon {
  color: #f5a623;
  font-size: 20px;
}

.winnerCard {
  margin-bottom: 16px;
  border-radius: 12px;
}

.infoIcon {
  margin-right: 8px;
  color: #1890ff;
}

.aboutParagraph {
  width: 100%;
  text-align: left;
}

.transactionLink {
  margin-top: 5px;
}