.user-tasks-container {
  max-width: 800px;
  padding: 16px;
}

.tasks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-card {
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s;
}

.premium-task {
  border: 2px solid gold;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.premium-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-progress-bar {
  margin-bottom: 12px;
}

.task-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.task-stats {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.task-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.create-task-button {
  margin: 16px 0;
  width: 100%;
}

/* Стили для деталей задания */
.task-details-container {
  width: 100%;
}

.task-details-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.task-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.task-details-progress {
  margin-bottom: 16px;
}

.task-details-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.task-detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.task-details-footer {
  margin-top: 16px;
}

.task-stats-summary {
  display: flex;
  justify-content: space-between;
}

.task-link-button {
  padding-left: 0;
}

/* Create Task Form Styles */
.task-cost-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 16px;
}

.task-balance-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
}

/* Стили для статистики */
.stats-card {
  margin-bottom: 20px;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.stats-content {
  display: flex;
  align-items: center;
}

.stats-icon {
  font-size: 24px;
  margin-right: 15px;
  color: #1890ff;
}

.stats-progress {
  flex: 1;
}

.stats-value {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  font-weight: bold;
}

.tasks-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Create Task Page Styles */
.create-task-page {
  padding: 16px;
  max-width: 800px;
  margin: 0 auto;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.back-button {
  margin-right: 16px;
}

.create-task-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
} 