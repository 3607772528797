.container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  margin-bottom: 20px;
}

.title {
  margin: 0;
  color: #1a1a1a;
}

.iconLinks {
  margin-bottom: 24px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
}

.blueIcon {
  color: #007aff;
  font-size: 20px;
}

.shareCard {
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.shareButton {
  background: #007aff;
  border-radius: 6px;
  width: 100%;
  height: 40px;
}

.loaderContainer {
  text-align: center;
  padding: 40px;
}

.card {
  border-radius: 12px;
}

.seniorPartnerCard {
  border-radius: 12px;
}

.levelTag {
  cursor: pointer;
}

.partnerBalanceCard {
  border-radius: 12px;
}

.modalTop {
  top: 20px;
}

/* Стили для PartnersLevelList */
.levelTitle {
  margin: 0;
}

.sortSelect {
  width: 100%;
  margin-top: 10px;
}

.statsCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.statValue {
  color: #007aff;
}

.partnerCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.partnerCard:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.avatar {
  background-color: #007aff;
}

.partnerInfo {
  flex: 1;
  margin-left: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.partnerName {
  font-size: 16px;
}

.partnerStats {
  text-align: right;
}

.loanAmount {
  color: #007aff;
  font-size: 16px;
  display: block;
}

.referralCount {
  margin-top: 4px;
}

.referralIcon {
  color: #52c41a;
  margin-right: 4px;
}

.paginationContainer {
  text-align: center;
  margin-top: 24px;
}

/* Стили для PartnersStatistics */
.statisticsTitle {
  margin: 0;
}

.levelCard {
  background: #f5f5f5;
  border-radius: 8px;
}

.levelCardPremium {
  cursor: pointer;
}

.levelCardNonPremium {
  cursor: default;
}

.totalCard {
  background: #e6f4ff;
  border-radius: 8px;
}

.totalValue {
  color: #007aff;
  font-size: 24px;
}

.notificationButton {
  background: #007aff;
  height: 40px;
  border-radius: 6px;
}

.notificationText {
  text-align: center;
}

/* Стили для InvestPartnerBalance */
.investContainer {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
}

.investCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 24px;
}

.investTitle {
  color: #1a1a1a;
  margin-top: 0;
  text-align: left;
}

.balanceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #f0f0f0;
}

.balanceLabel {
  font-size: 14px;
  color: #666;
}

.balanceValue {
  font-size: 14px;
  font-weight: 600;
  color: #007aff;
}

.termSelect {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.investButton {
  background: #007aff;
  border-radius: 6px;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
}

.partnerListCard {
  margin-top: 24px;
  border-radius: 12px;
}

/* Стили для PartnerList */
.historyItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.partnerName {
  font-weight: 500;
  color: #1a1a1a;
  text-decoration: none;
}

.partnerInfo {
  font-size: 12px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
}

/* Стили для шагов */
:global(.step-finish) {
  color: #52c41a !important;
}

:global(.step-finish .ant-steps-item-icon) {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}

:global(.step-finish .ant-steps-item-title),
:global(.step-finish .ant-steps-item-description) {
  color: #52c41a !important;
}

:global(.step-wait) {
  color: #d9d9d9 !important;
}

:global(.step-wait .ant-steps-item-icon) {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

:global(.step-wait .ant-steps-item-title),
:global(.step-wait .ant-steps-item-description) {
  color: #d9d9d9 !important;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow: auto;
  padding: 10px 0;
}

/* Стили для страницы выбора старшего партнёра */
.seniorPartnerInfoCard {
  margin-bottom: 16px;
  border-radius: 12px;
}

.sortContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.partnerStatItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.partnerStatValue {
  color: #007aff;
  font-weight: 500;
}

.partnerActionButtons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

@media (max-width: 576px) {
  .partnerActionButtons {
    flex-direction: column;
  }
  
  .partnerActionButtons button {
    width: 100%;
  }
}

.selectNewSeniorPartner {
  font-size: 12px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

/* Стили для страницы топ-10 старших партнёров */
.sortTabs {
  margin-top: 16px;
}

.sortTabs :global(.ant-tabs-nav) {
  margin-bottom: 0;
}

.partnerInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.topPartnerBadge {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.goldBadge {
  background-color: gold;
}

.silverBadge {
  background-color: silver;
}

.bronzeBadge {
  background-color: #cd7f32;
}