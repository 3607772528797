.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.statisticsContainer {
  padding: 16px;
}


.card {
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.titleIcon {
  font-size: 24px;
  color: #1890ff;
}

.titleWrapper {
  margin-bottom: 16px;
}

.ratingCircle {
  text-align: center;
}

.ratingValue {
  color: #1890ff;
  font-weight: bold;
  font-size: 24px;
}

.detailsCard {
  border-radius: 8px;
}

.loanScoreCard {
  background: #f0f8ff;
  border-radius: 8px;
}

.partnerScoreCard {
  background: #f0fff4;
  border-radius: 8px;
}

.postScoreCard {
  background: #fffbe6;
  border-radius: 8px;
}

.commentScoreCard {
  background: #fff7e6;
  border-radius: 8px;
}

.likeScoreCard {
  background: #f9f0ff;
  border-radius: 8px;
}

.chartContainer {
  margin-top: 24px;
  height: 200px;
}

.ratesChartContainer {
  width: 100%;
  height: 300px;
}

.statsCard {
  background: #f8fafc;
  border-radius: 12px;
}

.turnoverContainer {
  margin-top: 24px;
}

.turnoverLabel {
  display: block;
  margin-bottom: 16px;
}

.loanStatValue {
  color: #1890ff;
  font-size: 24px;
}

.tonValue {
  color: #0088CC;
}

.usdtValue {
  color: #26A17B;
}

.userStatValue {
  color: #52c41a;
}

.newUserStatValue {
  color: #faad14;
}

.webCreditStatValue {
  color: #eb2f96;
}